.loading-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    animation: fadeIn 0.3s forwards;
}

.loading-modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    width: 80%;
    max-width: 600px;
    opacity: 1;
    transform: scale(1);
    animation: scaleIn 0.3s forwards;
}

.loading-modal.fade-out {
    animation: fadeOut 0.3s forwards, scaleOut 0.3s forwards;
}

.loading-modal-content {
    text-align: center;
}

.loading-animation {
    text-align: center;
    font-family: Arial, sans-serif;
}

.loading-text {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.loading-dots span {
    animation: blink 1s infinite;
    font-size: 2em;
}

.loading-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes scaleOut {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.9);
    }
}

@keyframes blink {
    0%, 20%, 50%, 80%, 100% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Custom CSS for Navbar dropdown, 
 *right hand side login as it ran out of the window 
 */
.navbar .dropdown-menu {
  right: auto;
  left: -10px; /* Adjust as needed */
}

/* Custom CSS targeting the custom-dropdown class */
.custom-dropdown .dropdown-menu {
  right: auto;
  left: -170px; /* Adjust as needed */
}

.custom-dropdown-menu .dropdown-menu {
  right: auto;
  left: -80px; /* Adjust as needed */
}

/* CSS for JourneyBar component */
.journey-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*background-color: #f0f0f0;*/
  border-radius: 5px;
  overflow: hidden;
}

.journey-bar-step {
  
  padding: 8px 12px;
  margin-right: 10px;
  border-radius: 20px;
  /*background-color: #f9f9f9;
  color: #333;*/
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  
  text-align: center;
  padding: 10px 0;
  color: #000;
  background-color: #fff;
  border: 1px solid #585858;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-size: 12px;

  margin: 5px;
  max-width: 130px;
  min-width: 80px;
}

.journey-bar-step.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  /*background-color: #28a745;*/
}

@media (max-width: 1000px) {
  /* Adjust the font size of journey bar labels for smaller screens */
  .journey-bar-step {
    font-size: 8px;
  }
}

/* context menu in the actor mapping page */ 

.context-menu {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.context-menu-item {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: #eee;
}

/* CSS for the carousel */

.carousel-control-prev,
.carousel-control-next {
  width: 25%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: gray;
  border-radius: 10%;
  padding: 10px;
}


/* fancy button */

.fancy-button {
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fancy-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3), transparent);
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  border-radius: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

.fancy-button:hover::before {
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
}

.fancy-button:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.fancy-button:active {
  transform: scale(0.95);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.fancy-button:focus {
  outline: none;
}

/* Secondary Fancy Button, added December 8, 2024 */

.secondary-fancy-button {
  background: linear-gradient(45deg, #e0e0e0, #f5f5f5);
  color: #6a11cb; /* Complementary initial text color */
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.secondary-fancy-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3), transparent);
  transition: width 0.5s ease, height 0.5s ease, top 0.5s ease, left 0.5s ease;
  border-radius: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

.secondary-fancy-button:hover::before {
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
}

.secondary-fancy-button:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Softer shadow */
  color: #000; /* Text color changes to black when hovered */
}

.secondary-fancy-button:active {
  transform: scale(0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.secondary-fancy



/* Filter repository top bar */

.filter-bar {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filter-item {
  margin-right: 10px;
  min-width: 150px;
}

@media (max-width: 768px) {
  .filter-item {
    min-width: 100px;
    margin-bottom: 10px;
  }
}

/* filter container */

.filter-container {
  background-color: #f8f9fa; /* Light gray background for the whole filter section */
  padding: 10px;
  border-radius: 5px;
}

.filter-bar {
  background-color: #ffffff; /* White background for the filter bar */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.active-filters {
  background-color: #ffffff; /* White background for the active filter tags */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Text input box in the interview component */

/* Container for line numbers */
.line-numbers {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  padding-top: 10px;
  font-family: monospace;
  font-size: 14px;
  color: #aaa;
  text-align: right;
  line-height: 1.5;
  pointer-events: none;
}

.styled-textarea {
  width: 100%;
  height: 200px;
  padding: 10px 10px 10px 50px; /* Padding to accommodate line numbers */
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: #fdfdfd;
  outline: none;
  transition: box-shadow 0.3s ease;
}

/* Add shadow and slight color change on focus */
.styled-textarea:focus {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}

/* Subtle "paper" effect background */
.styled-textarea {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 1px, transparent 1px);
  background-size: 100% 1.5em;
}


/* PE CUBE */

.cube-container {
  perspective: 1500px;
  width: 300px;
  height: 300px;
  margin: auto;
  position: relative;
  padding-top: 100px;
  padding-bottom: 400px;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-45deg);
  transition: transform 0.6s ease;
  cursor: grab;
  background: linear-gradient(145deg, #f0f0f0, #d9d9d9);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.cube:active {
  cursor: grabbing;
}

/* Styling each face of the cube */
.cube-face {
  position: absolute;
  width: 300px;
  height: 300px;
  background: rgba(240, 240, 240, 0.9);
  border: 1px solid #bbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
  color: #333;
  box-sizing: border-box;
  border-radius: 10px;
  background: linear-gradient(135deg, #e8e8e8, #f2f2f2);
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.1), inset -3px -3px 6px rgba(255, 255, 255, 0.6);
}

/* Positioning for each face */
.cube-face.front { transform: rotateY(0deg) translateZ(150px); }
.cube-face.back { transform: rotateY(180deg) translateZ(150px); }
.cube-face.top { transform: rotateX(90deg) translateZ(150px); }
.cube-face.bottom { transform: rotateX(-90deg) translateZ(150px); }

/* Two-column vertical layout for left and right faces with rotated text */
.cube-face.left, .cube-face.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around; /* Space columns evenly within the face */
  padding: 10px;
}

.cube-face.left {
  transform: rotateY(-90deg) translateZ(150px);
}

.cube-face.right {
  transform: rotateY(90deg) translateZ(150px);
}

/* Individual columns for each set of items */
.cube-face.left .cube-column, .cube-face.right .cube-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; /* Fill the height of the face */
}

/* Rotate each item to display text normally within columns */
.cube-face.left .cube-column .cube-item, .cube-face.right .cube-column .cube-item {
  transform: rotate(-90deg); /* Rotate text for readability */
  width: 100%;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  background: linear-gradient(135deg, #e8e8e8, #f2f2f2);
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Remove bottom border for the last item */
.cube-face.left .cube-column .cube-item:last-child,
.cube-face.right .cube-column .cube-item:last-child {
  border-bottom: none;
}

/* Styling each cube item with a top border for separation */
.cube-item {
  padding: 15px 0;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: background 0.3s ease;
  background: linear-gradient(135deg, #e8e8e8, #f2f2f2);
  color: #333;
  font-size: 16px;
  box-sizing: border-box;
  border-top: 1px solid #ddd; /* Thin line between items */
}

.cube-item:first-child {
  border-top: none; /* Remove top border for the first item */
}

.cube-item:hover {
  background: #dcdcdc;
}

/* Rotation controls styling */
.rotation-controls button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, box-shadow 0.3s;
}

.rotation-controls button:hover {
  background: #0056b3;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

/* PlatformEconomyCube.css */

.cube-container {
  perspective: 1500px;
  width: 300px;
  height: 300px;
  margin: auto;
  position: relative;
  padding-top: 100px;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-45deg);
  transition: transform 0.6s ease;
  cursor: grab;
}

.cube:active {
  cursor: grabbing;
}

/* Styling each face of the cube */
.cube-face {
  position: absolute;
  width: 300px;
  height: 300px;
  background: rgba(240, 240, 240, 0.9);
  border: 1px solid #bbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #333;
  box-sizing: border-box;
  padding-top: 40px; /* Add padding to prevent label overlap */
}

.cube-face.front { transform: rotateY(0deg) translateZ(150px); }
.cube-face.back { transform: rotateY(180deg) translateZ(150px); }
.cube-face.left { transform: rotateY(-90deg) translateZ(150px); }
.cube-face.right { transform: rotateY(90deg) translateZ(150px); }
.cube-face.top { transform: rotateX(90deg) translateZ(150px); }
.cube-face.bottom { transform: rotateX(-90deg) translateZ(150px); }

.face-label {
  position: absolute;
  top: 5px;
  font-weight: bold;
  z-index: 1; /* Ensure label appears above treemap */
  /*background: rgba(255, 255, 255, 0.8); /* Optional: add background to improve readability */
  padding: 3px 6px;
  border-radius: 4px;
}

/* Rotation controls styling */
.rotation-controls {
  text-align: center;
  margin-top: 20px;
  position: relative;
  z-index: 2; /* Ensure controls appear above cube */
}

/* LP design, flying icons */

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(8px, -8px);
  }
  50% {
    transform: translate(-12px, 12px);
  }
  75% {
    transform: translate(-8px, -8px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/*
@keyframes drift {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-20px, 20px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}
*/


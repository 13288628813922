.module {
    padding: 20px;
    border: 2px solid #007bff;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
  
  .module-title {
    color: #007bff;
    font-weight: bold;
  }
  
  .semantic-tree ul {
    list-style: none;
    padding-left: 20px;
  }
  
  .semantic-tree ul li {
    margin: 10px 0;
    position: relative;
    padding: 5px 0;
  }
  
  .semantic-tree ul ul {
    padding-left: 20px;
  }
  
  .semantic-tree ul li::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    left: -10px;
    top: 0;
    background: #007bff;
  }
  
  .matching-module ul {
    list-style: none;
    padding-left: 0;
  }
  
  .matching-module ul li strong {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .matching-module ul ul {
    padding-left: 20px;
  }
  
  .generator-module {
    text-align: center;
    color: #495057;
  }
  
  .generator-module ul {
    list-style: none;
    padding: 0;
  }
  
  .generator-module ul li {
    margin: 5px 0;
    font-size: 16px;
    color: #007bff;
  }
  
  .architecture {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .architecture-box {
    width: 100px;
    height: 100px;
    background-color: #e7f3ff;
    border: 1px solid #007bff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    color: #007bff;
  }
  
  .fancy-arrow {
    font-size: 50px;
    color: #007bff;
  }
  
  


/* General module styling */
.module {
    padding: 20px;
    border: 2px solid #007bff;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .module:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.2);
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .fancy-arrow {
    font-size: 40px;
    color: #007bff;
  }
  
  /* Architecture styling */
  .architecture-box {
    padding: 10px;
    background-color: #e7f3ff;
    border: 1px solid #007bff;
    border-radius: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold;
  }
  
  /* Modal adjustments */
  /*
  .modal-content {
    border: 2px solid #007bff;
  }
  */

  /* Vis Generator Module */


  .flow-box {
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin: 5px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .flow-box.small-box {
    width: 80px;
    height: 90px;
  }
  .flow-box h6 {
    font-size: 12px;
    margin-top: 5px;
    color: #333;
  }
  .flow-box:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.2);
  }
  
  .flow-arrow {
    font-size: 20px;
    color: #007bff;
  }
  
  .generator-flow {
    gap: 10px;
  }
  